import { render, staticRenderFns } from "./batchShutdown.vue?vue&type=template&id=437f8817&scoped=true&"
import script from "./batchShutdown.vue?vue&type=script&lang=js&"
export * from "./batchShutdown.vue?vue&type=script&lang=js&"
import style0 from "./batchShutdown.vue?vue&type=style&index=0&id=437f8817&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437f8817",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('437f8817')) {
      api.createRecord('437f8817', component.options)
    } else {
      api.reload('437f8817', component.options)
    }
    module.hot.accept("./batchShutdown.vue?vue&type=template&id=437f8817&scoped=true&", function () {
      api.rerender('437f8817', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/socialSecurity/archives/components/batchShutdown.vue"
export default component.exports