var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            title: "批量停保",
            closable: false,
            size: "small",
            "z-index": 2,
            visible: _vm.visible,
          },
          on: { close: _vm.onClose, onSubmit: _vm.onSubmin },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "适用员工",
                        "wrapper-col": _vm.wrapperCols,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "per_list",
                          style: !_vm.flag ? _vm.avatarClassDefault : "",
                        },
                        [
                          _vm._l(_vm.form.staff, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "per_list_avatar" },
                              [
                                _c("CbAvatar", {
                                  attrs: {
                                    "on-job-id": item.onJobId,
                                    "avater-name": item.staffName,
                                    size: "24",
                                    "default-name-length": 1,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _vm.form.staff.length > 8
                            ? _c(
                                "div",
                                {
                                  staticClass: "downUp",
                                  on: {
                                    click: function ($event) {
                                      _vm.flag = !_vm.flag
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "iconpark-icon",
                                      style: !_vm.flag
                                        ? "transform: rotate(270deg)"
                                        : "transform: rotate(90deg)",
                                    },
                                    [_c("use", { attrs: { href: "#left" } })]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "停保", prop: "stopMain" } },
                    [
                      _c(
                        "a-checkbox-group",
                        {
                          model: {
                            value: _vm.form.stopMain,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "stopMain", $$v)
                            },
                            expression: "form.stopMain",
                          },
                        },
                        [
                          _c(
                            "a-checkbox",
                            { attrs: { value: "1", name: "type" } },
                            [_vm._v("社保")]
                          ),
                          _c(
                            "a-checkbox",
                            { attrs: { value: "2", name: "type" } },
                            [_vm._v("医保")]
                          ),
                          _c(
                            "a-checkbox",
                            { attrs: { value: "3", name: "type" } },
                            [_vm._v("公积金")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.stopMain.includes("1")
                    ? _c(
                        "div",
                        [
                          _c("BlockTitle", {
                            attrs: { id: "title", title: "社保" },
                          }),
                          _c(
                            "a-form-model-item",
                            {
                              ref: "socialMonth",
                              attrs: {
                                label: "缴纳停止月",
                                prop: "socialMonth",
                              },
                            },
                            [
                              _c("a-month-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择月份",
                                  "value-format": "YYYY-MM",
                                },
                                on: {
                                  blur: function () {
                                    _vm.$refs.socialMonth.onFieldBlur()
                                  },
                                },
                                model: {
                                  value: _vm.form.socialMonth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "socialMonth", $$v)
                                  },
                                  expression: "form.socialMonth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "减员原因" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.form.socialReason,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "socialReason", $$v)
                                    },
                                    expression: "form.socialReason",
                                  },
                                },
                                _vm._l(
                                  _vm.codeName.social_decreases_reason,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(" " + _vm._s(item.content) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.stopMain.includes("2")
                    ? _c(
                        "div",
                        [
                          _c("BlockTitle", {
                            attrs: { id: "title", title: "医保" },
                          }),
                          _c(
                            "a-form-model-item",
                            {
                              ref: "medicalMonth",
                              attrs: {
                                label: "缴纳停止月",
                                prop: "medicalMonth",
                              },
                            },
                            [
                              _c("a-month-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择月份",
                                  "value-format": "YYYY-MM",
                                },
                                on: {
                                  blur: function () {
                                    _vm.$refs.medicalMonth.onFieldBlur()
                                  },
                                },
                                model: {
                                  value: _vm.form.medicalMonth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "medicalMonth", $$v)
                                  },
                                  expression: "form.medicalMonth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "减员原因" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.form.medicalReason,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "medicalReason", $$v)
                                    },
                                    expression: "form.medicalReason",
                                  },
                                },
                                _vm._l(
                                  _vm.codeName.medical_decreases_reason,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(" " + _vm._s(item.content) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.stopMain.includes("3")
                    ? _c(
                        "div",
                        [
                          _c("BlockTitle", {
                            attrs: { id: "title", title: "公积金" },
                          }),
                          _c(
                            "a-form-model-item",
                            {
                              ref: "accumulationMonth",
                              attrs: {
                                label: "缴纳停止月",
                                prop: "accumulationMonth",
                              },
                            },
                            [
                              _c("a-month-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "get-popup-container": function (
                                    triggerNode
                                  ) {
                                    return triggerNode.parentNode
                                  },
                                  placeholder: "请选择月份",
                                  "value-format": "YYYY-MM",
                                },
                                on: {
                                  blur: function () {
                                    _vm.$refs.accumulationMonth.onFieldBlur()
                                  },
                                },
                                model: {
                                  value: _vm.form.accumulationMonth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "accumulationMonth", $$v)
                                  },
                                  expression: "form.accumulationMonth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "变更原因" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "get-popup-container": function (
                                      triggerNode
                                    ) {
                                      return triggerNode.parentNode
                                    },
                                  },
                                  model: {
                                    value: _vm.form.accumulationReason,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "accumulationReason",
                                        $$v
                                      )
                                    },
                                    expression: "form.accumulationReason",
                                  },
                                },
                                _vm._l(
                                  _vm.codeName.housing_decreases_reason,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(" " + _vm._s(item.content) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }